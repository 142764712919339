export default class RegionDTO {
  code: string
  is_updatable: boolean
  has_houses: boolean;
  has_address_objects: boolean;

  constructor (
    code: string,
    is_updatable: boolean,
    has_houses: boolean,
    has_address_objects: boolean
  ) {
    this.code = code
    this.is_updatable = is_updatable
    this.has_houses = has_houses;
    this.has_address_objects = has_address_objects;
  }
}
